import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import PageHead from '../../components/PageHead';
import { DemoButton } from '../../components/DemoButton';
import {
  FeatureGrid,
  TrafficSources,
  TrafficSourcesNewsletter,
  TrafficSourcesOrganicIsolation,
  TrafficSourcesPaidCampaigns,
  TrafficSourcesByArticle,
  TrafficSourcesAudienceDevelopment
} from '../../components/FeatureGrid';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/traffic-sources/"
          title="Traffic Source Reporting for Affiliate Publishers"
          description="Track affiliate earnings back to traffic source, UTMs, and paid campaigns."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '630px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Track affiliate earnings back to traffic source, UTMs, and paid
            campaigns.
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Use Traffic Sources to partner with audience development, social,
            and sales teams to optimize paid search or paid social campaigns.
            Trace earnings back to sources like Facebook, Instagram, and your
            newsletter.
          </Typography>
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <DemoButton variant="contained" />
          </div>
          <img
            src="/images/features/traffic-sources-empty-state.png"
            style={{
              width: '80%',
              maxWidth: '700px',
              margin: '48px auto',
              display: 'block'
            }}
            alt="Traffic source reporting"
          />
        </PageHead>
        <Typography
          variant="h4"
          gutterBottom
          component="h2"
          style={{
            textAlign: 'center',
            maxWidth: '630px',
            margin: '0 auto 24px',
            fontWeight: 900
          }}
        >
          Use cases
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            maxWidth: '510px',
            margin: '24px auto 36px',
            textAlign: 'center'
          }}
        >
          Here are a few ways publishers are using Traffic Sources today.
        </Typography>
        <FeatureGrid>
          <TrafficSources />
          <TrafficSourcesOrganicIsolation />
          <TrafficSourcesPaidCampaigns />
          <TrafficSourcesNewsletter />
          <TrafficSourcesByArticle />
          <TrafficSourcesAudienceDevelopment />
        </FeatureGrid>
      </div>
    </MainLayout>
  );
};
